import React, { useEffect, useMemo, useState } from 'react';
import '../styles.css';
import DragAndDrop from './DragAndDrop';
import StyledSelect from '../../../../components/common/select';
import { MESSAGES } from '../../../../constant';
import { createVideoInsight, editVideoInsight, editContent as editInsightContent } from '../../../../actions';
import Input from '../../../../components/common/input';
import TextArea from '../../../../components/common/textArea';
import { BLOG, CREATE_MODALS, PDF, contentTypes } from '../../../../constant/insights';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  blobToFile,
  getContentType,
  getDefaultThumbnail,
  getEditContentType,
  getElementId,
  getElementName,
} from '../../../../helpers';

const initialValues = {
  name: '',
  category: '',
  description: '',
  blob: '',
  thumbnail: '',
  section: '',
  content_type: '',
  blog_url: '',
  duration: '',
};

const NewContentForm = ({ setModalType, setNewElementModalOpen, resetFields, editContent }) => {
  const [values, setValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const sections = useSelector((state) => state.insightDashboard.sections);
  const tags = useSelector((state) => state.insightDashboard.tags);
  const content = useSelector((state) => state.insightDashboard.content);
  const dispatch = useDispatch();

  const sectionOptions = useMemo(() => {
    return sections?.map((section) => ({
      id: section?.id,
      label: section.name,
      name: 'section',
    }));
  }, [sections]);

  const tagsOptions = useMemo(() => {
    return tags?.map((tag) => ({
      ...tag,
      label: tag?.name,
      name: 'category',
    }));
  }, [tags]);

  const clearErrors = () => {
    setValues(initialValues);
    setFormErrors({});
    setError(false);
  };

  const closeModal = () => {
    setModalType('');
    clearErrors();
  };

  const handleChange = (value, name) => {
    if (name === 'section' && value !== '') setFormErrors({ ...formErrors, section: false });
    if (name === 'blob' && value !== '') setFormErrors({ ...formErrors, blob: false });
    if (name === 'content_type') {
      setValues({ ...values, [name]: value, blob: '', duration: '', thumbnail: '' });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleBlogDataChange = async (value) => {
    setValues({ ...values, blog_url: value, thumbnail: '' });
  };

  const formValidation = values.name === '' || values.description.length > 300 || values.section === '';

  const formValidationErrors = {
    name: values.name === '',
    section: values.section === '',
    description: values.description.length > 300,
  };

  const getBodyData = async () => {
    const thumbnail_name = values?.blob?.name?.split('.') || 'external_url_default.jpg';
    const externalURL = values.content_type === BLOG ? values.blog_url : '';
    const blobData = values.content_type === BLOG ? null : values?.blob;
    const thumbnail = values.thumbnail && thumbnail_name && blobToFile(values?.thumbnail, `${thumbnail_name[0]}.jpg`);
    const bodyFormData = new FormData();
    bodyFormData.append('name', values.name);
    values.category?.length
      ? values.category.forEach((category, index) =>
          bodyFormData.append(`tags_id[${index}]`, getElementId(category, tagsOptions)),
        )
      : bodyFormData.append(`tags_id`, []);
    bodyFormData.append('section_id', getElementId(values.section, sectionOptions));
    bodyFormData.append('description', values.description);
    bodyFormData.append('content_type', getContentType(values.content_type));
    bodyFormData.append('duration', values.duration);
    bodyFormData.append('blog_url', externalURL);
    values?.blob && bodyFormData.append('file', blobData);
    bodyFormData.append('thumbnail', values?.thumbnail ? thumbnail : await getDefaultThumbnail(values?.content_type));
    return bodyFormData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEmpty(editContent) && values.content_type !== BLOG ? formValidation || values.blob === '' : formValidation) {
      setFormErrors(
        isEmpty(editContent) ? { ...formValidationErrors, blob: values.blob === '' } : formValidationErrors,
      );
    } else if (values.content_type === BLOG && (formValidation || values.blog_url === '')) {
      setFormErrors({ ...formValidationErrors, blog_url: values.blog_url === '' });
    } else {
      setLoading(true);
      const bodyFormData = await getBodyData();
      try {
        let response = [];
        if (isEmpty(editContent)) {
          response = await createVideoInsight(bodyFormData);
          dispatch(editInsightContent(response, content, true));
        } else {
          response = await editVideoInsight(bodyFormData, editContent.id);
          dispatch(editInsightContent(response, content));
        }
        closeModal();
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (resetFields) clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFields]);

  useEffect(() => {
    if (!isEmpty(editContent)) {
      setValues({
        ...editContent,
        category: editContent?.tag_names?.split(', ').map((name) => name),
        section: getElementName(editContent.section_id, sectionOptions),
        content_type: getEditContentType(editContent.content_type),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editContent]);

  return (
    <form id="videoInsight" className="insights-form" onSubmit={(e) => handleSubmit(e)}>
      <span>Content information</span>
      <div className="field">
        <Input
          value={values.name}
          name="name"
          errors={formErrors}
          handleChange={(value) => handleChange(value, 'name')}
          clearError={(value) => setFormErrors({ ...formErrors, name: value })}
          errorMessage={MESSAGES.MANDATORY}
          label="Name"
        />
      </div>
      <StyledSelect
        value={values.section}
        options={sectionOptions}
        defaultOption="Select Section"
        handleChange={handleChange}
        error={formErrors.section}
        isCreatable={false}
      />
      <StyledSelect
        value={values.category}
        options={tagsOptions}
        defaultOption="Select Tags"
        handleChange={handleChange}
        error={formErrors.category}
        handleCreate={() => setNewElementModalOpen(CREATE_MODALS.CATEGORY)}
        multiselect
      />
      <div className="field">
        <TextArea
          value={values.description}
          name="description"
          errors={formErrors}
          handleChange={(value) => handleChange(value, 'description')}
          clearError={(value) => setFormErrors({ ...formErrors, description: value })}
          errorMessage={MESSAGES.MAX_LENGTH}
          label="Description"
          rows="6"
        />
      </div>
      <span>Upload Insight</span>
      <StyledSelect
        value={values.content_type === 'undefined' ? 'Video' : values.content_type}
        options={contentTypes}
        defaultOption="Select Content Type"
        handleChange={handleChange}
        error={formErrors.category}
        isCreatable={false}
        isSearchable={false}
      />

      {values.content_type === '' ? null : values.content_type === BLOG ? (
        <Input
          value={values.blog_url}
          name="blog_url"
          errors={formErrors}
          handleChange={(value) => handleBlogDataChange(value)}
          clearError={(value) => setFormErrors({ ...formErrors, blog_url: value })}
          errorMessage={MESSAGES.MANDATORY}
          label="URL"
        />
      ) : (
        <div className="field">
          <DragAndDrop
            handleChange={handleChange}
            blob={values.blob}
            thumbnail={values.thumbnail}
            contentType={values.content_type}
            editContent={editContent}
          />
          {!!formErrors.blob && <span className="error-message">{MESSAGES.MANDATORY}</span>}
        </div>
      )}

      {!!error && (
        <div className="error-request">
          <span>{MESSAGES.ERROR_REQUEST}</span>
        </div>
      )}
      <button form="videoInsight" type="submit">
        {loading ? <span className="loader" /> : isEmpty(editContent) ? 'Create Asset' : 'Save changes'}
      </button>
    </form>
  );
};

export default NewContentForm;
