import React, { useState } from 'react';
import { ReactComponent as Clock } from '../../../../assets/img/time.svg';
import { ReactComponent as PlayPreview } from '../../../../assets/img/play.svg';
import '../styles.css';
import Input from '../../input';
import _ from 'lodash';
import {
  getGoogleDriveContent,
  getOneDriveContent,
  getVimeoContent,
  getYtbContent,
} from '../../../../helpers/videoPreview';
import { useDispatch, useSelector } from 'react-redux';
import { setDigestValues } from '../../../../actions';
import { getRandomString } from '../../../../helpers';
import { MESSAGES } from '../../../../constant';

const youtubeContent = 'youtu';
const vimeoContent = 'vimeo';
const oneDriveContent = 'drv';
const googleDrive = 'drive';

const NewContent = ({ handleClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({ new: false });
  const [loading, setLoading] = useState(false);
  const [videoData, setVideoData] = useState({ thumbnail: '', title: '', duration: '', description: '' });
  const [videoIssue, setVideoIssue] = useState(false);
  const digestValues = useSelector((state) => state.insightDashboard.digestValues);
  const { content: items } = digestValues;
  const dispatch = useDispatch();

  const videoDataFilled = _.values(videoData).some((x) => x !== '');

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    setVideoIssue(false);
    setLoading(true);
    loadPreview(newValue);
  };

  const loadPreview = (value) => {
    switch (true) {
      case value.includes(youtubeContent):
        return getYtbContent(value, setVideoData, setLoading, setVideoIssue);
      case value.includes(vimeoContent):
        return getVimeoContent(value, setVideoData, setLoading, setVideoIssue);
      case value.includes(oneDriveContent):
        return getOneDriveContent(value, setVideoData, setLoading, setVideoIssue);
      case value.includes(googleDrive):
        return getGoogleDriveContent(value, setVideoData, setLoading, setVideoIssue);
      case ![youtubeContent, vimeoContent, oneDriveContent, googleDrive].includes(value):
        setVideoData({ thumbnail: '', title: '', duration: '', description: '' });
        setLoading(false);
        setVideoIssue(true);
        return;
    }
  };

  const handleClearErrors = (value) => {
    setErrors({ new: value });
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSubmit = async (e) => {
    const externalValue = {
      id: getRandomString(),
      name: videoData?.title,
      description: videoData?.description,
      url: inputValue,
      thumbnail: videoData?.thumbnail,
      duration: videoData?.duration,
      noExternal: true,
    };
    e.preventDefault();
    dispatch(
      setDigestValues({
        ...digestValues,
        content: [{ ...externalValue, externalShare: false }, ...items],
        externalContent: [externalValue, ...digestValues?.externalContent],
      }),
    );
    handleClose();
  };

  return (
    <form className="creation-form" onSubmit={handleSubmit}>
      <Input
        value={inputValue}
        name="new"
        errors={errors}
        handleChange={handleInputChange}
        clearError={handleClearErrors}
        errorMessage="Content link is required"
        placeholder="https://"
        url
      />
      <p className="content-description">Supported links: Google Drive, One Drive, Vimeo and YouTube</p>
      <div style={{ display: loading ? 'flex' : 'none' }} className="loader-wrapper">
        <span className="progressLoader" />
        <span className="progressLoader-label">Loading Preview</span>
      </div>
      {videoIssue && <span className="preview-error">{MESSAGES.ERROR_PREVIEW}</span>}
      {videoDataFilled && !loading && (
        <div className="preview">
          <div className="preview-thumbnail">
            <img className="external-video-preview" src={videoData.thumbnail} />
            <a href={inputValue} target="_blank" rel="noopener noreferrer">
              <PlayPreview />
            </a>
          </div>

          <div className="preview-description-wrapper">
            <span className="preview-title">{videoData.title}</span>
            <span className="preview-duration">
              <Clock />
              <span>{videoData.duration}</span>
            </span>
            <p className="preview-description">{videoData.description || 'No description available'}</p>
          </div>
        </div>
      )}
      <div className="preview-button-wrapper">
        {videoDataFilled && !loading && (
          <>
            <button className="cancel-button" type="button" onClick={handleCancel}>
              Cancel
            </button>
            <button disabled={!videoDataFilled || loading} type="submit">
              Add External Content
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default NewContent;
